<template>
  <div
    :class="
      `w-full h-full flex justify-center items-center border ${colorDefault}`
    "
    @dragover.prevent="dragoverBox"
    @dragleave="dragleaveBox"
    @drop.stop.prevent="dropFile"
  >
    <label>
      <span :class="`text-${color}-400 cursor-pointer`">
        将文件拖放到此区域或单击本行文字上传
      </span>
      <input @change="fileChange" type="file" class="hidden" multiple />
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Upload",
  props: {
    color: { type: String, default: "blue" },
  },
  setup(props, { emit }) {
    const colorDefault = `border-${props.color}-200`;
    const colorPrimary = `border-${props.color}-400`;
    const dragoverBox = (e) => {
      e.target.classList.add(colorPrimary);
      e.target.classList.remove(colorDefault);
    };
    const dragleaveBox = (e) => {
      e.target.classList.remove(colorPrimary);
      e.target.classList.add(colorDefault);
    };
    const dropFile = (e) => {
      e.target.classList.remove(colorPrimary);
      e.target.classList.add(colorDefault);
      emit("fileSelected", [...e.dataTransfer.files]);
    };
    const fileChange = (e) => {
      emit("fileSelected", [...e.target.files]);
    };
    return {
      colorDefault,
      colorPrimary,
      dragoverBox,
      dragleaveBox,
      dropFile,
      fileChange,
    };
  },
});
</script>
