<template>
  <div class="w-full flex flex-row">
    <div
      class="w-24 h-24 relative border cursor-pointer hover:border-blue-400"
      v-for="(img, idx) in imageList"
      :key="idx"
      @click="() => imageSelected(idx)"
    >
      <img :src="img.src" class="w-full h-full object-scale-down" />
      <span
        class="absolute left-0 bottom-0 max-w-full h-7 text-center  bg-white opacity-80 overflow-hidden"
        >{{ img.name }}</span
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    imageList: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const imageSelected = (idx) => {
      emit("imageSelected", idx);
    };
    return { imageSelected };
  },
});
</script>
