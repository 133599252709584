<template>
  <div class="w-full h-full flex flex-col p-2">
    <div class="h-24">
      <upload @fileSelected="fileSelected" color="blue" />
    </div>
    <image-list
      class="py-2"
      v-show="imagesBase64.length"
      :imageList="imagesBase64"
      @imageSelected="imageSelected"
    />

    <div class="flex flex-row w-full h-full overflow-auto">
      <div class="w-1/2 max-h-full p-2">
        <vue-picture-cropper
          v-if="currentImage"
          :img="currentImage.src"
          :options="{
            viewMode: 1,
            dragMode: 'crop',
          }"
          :boxStyle="{
            'object-fit': 'scale-down',
          }"
        />
      </div>
      <div class="w-1/2 h-full flex flex-col p-2">
        <div class="h-1/2 border">
          <img
            v-if="croppedImage"
            :src="croppedImage.src"
            class="w-full h-full object-scale-down"
          />
        </div>
        <div class="h-1/2 p-2">
          <div class="flex flex-row items-center">
            <span class="text-gray-500 text-sm pr-2">识别类型</span>
            <el-select
              v-model="ocrSelected"
              filterable
              placeholder="请选择"
              size="small"
              class="w-32"
              @change="ocrSelectedChange"
            >
              <el-option
                v-for="item in ocrOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-button @click="cropAndRecognize" size="small" class="ml-3"
              >裁切并识别</el-button
            >
            <el-button @click="crop" size="small">裁切</el-button>
            <el-button @click="ocr" size="small">识别</el-button>
          </div>

          <div
            v-if="result && Object.keys(result).length"
            class="w-full flex flex-col space-y-1 py-2 text-gray-500"
          >
            <div
              v-for="item in result"
              :key="item.label"
              class="flex items-center"
            >
              <span class="w-32">{{ item.label }}</span>
              <span class="w-full">{{ item.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import Upload from "@/components/Upload.vue";
import ImageList from "../../components/ImageList.vue";
import { useStore } from "vuex";
import { ElNotification } from "element-plus";

export default defineComponent({
  name: "OCR",
  components: { Upload, ImageList, VuePictureCropper },
  setup() {
    const store = useStore();
    const files = ref([]);
    // { name, src }
    const imagesBase64 = ref([]);
    const currentImage = ref();
    const croppedImage = ref();
    const result = ref();

    const ocrOptions = [
      { label: "身份证", value: "idcard" },
      { label: "营业执照", value: "business_license" },
      { label: "二维码", value: "qrcode" },
      { label: "地址二维码", value: "addr_qrcode" },
      { label: "银行卡", value: "bankcard" },
      { label: "通用文本", value: "accurate_basic" },
    ];

    const ocrSelected = ref("idcard");

    const readImage = (img) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        imagesBase64.value.push({ name: img.name, src: reader.result });
        // 选定首张图片，以显示再编辑区
        if (!croppedImage.value && imagesBase64.value.length) {
          currentImage.value = imagesBase64.value[0];
        }
      };
      reader.readAsDataURL(img);
    };

    const fileSelected = (newFiles) => {
      files.value = files.value.concat(newFiles);
      const images = newFiles.filter(
        (i) => i.type && i.type.startsWith("image/")
      );
      images.map((i) => readImage(i));
    };

    const imageSelected = (idx) => {
      currentImage.value = imagesBase64.value[idx];
    };

    const ocrSelectedChange = () => (result.value = null);

    const crop = () => {
      croppedImage.value = { src: cropper.getDataURL() };
    };

    const ocr = async () => {
      const payload = {
        func: "ocr",
        pkg: "app.recognition.baidu",
        kwargs: {
          category: ocrSelected.value,
          data: { image: croppedImage.value.src },
        },
      };
      store
        .dispatch("helper/run", payload)
        .then((res) => (result.value = res.data))
        .catch((e) =>
          ElNotification.error({
            message: `识别失败，请检查是不是选择了正确的识别类型.${e}`,
          })
        );
    };

    const cropAndRecognize = () => {
      crop();
      ocr();
    };

    return {
      files,
      fileSelected,
      imagesBase64,
      ocrOptions,
      ocrSelected,
      imageSelected,
      currentImage,
      croppedImage,
      ocrSelectedChange,
      crop,
      ocr,
      cropAndRecognize,
      result,
    };
  },
});
</script>
